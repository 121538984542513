import classNames from "classnames";
import Section from "../components/Section";
import {BottomNavigator} from "../components/Navbar";
import {useTranslation} from "react-i18next";

export const RoadmapCard = ({color, year, period, title, subTitle1, subTitle2, subTitle3, subTitle4, subTitle5, content, content2, content3, content4, content5, className}) => {
    const {t} = useTranslation();
    return (
        <div className={ classNames('RoadmapCard', `RoadmapCard--${color}`, className) }>
            <div className={'RoadmapCard__left'}>
                <div className={'RoadmapCard__year'}>
                    {year}
                </div>
                <div className={'RoadmapCard__period'}>
                    {period}
                </div>
            </div>
            <div className={'RoadmapCard__pill'}/>
            <div className={'RoadmapCard__right'}>
                <div className={'RoadmapCard__title'}>
                    {title}
                </div>
                {
                    subTitle1 &&
                    <><div className={'RoadmapCard__subTitle'}>
                        {subTitle1}
                    </div>
                    <div className={'RoadmapCard__content'}>
                        {t(content)}
                    </div></>
                }
                {
                    subTitle2 &&
                    <><div className={'RoadmapCard__subTitle'}>
                        {subTitle2}
                    </div>
                    <div className={'RoadmapCard__content'}>
                        {t(content2)}
                    </div></>
                }
                {
                    subTitle3 &&
                    <><div className={'RoadmapCard__subTitle'}>
                        {subTitle3}
                    </div>
                    <div className={'RoadmapCard__content'}>
                        {t(content3)}
                    </div></>
                }
                {
                    subTitle4 &&
                    <><div className={'RoadmapCard__subTitle'}>
                        {subTitle4}
                    </div>
                    <div className={'RoadmapCard__content'}>
                        {t(content4)}
                    </div></>
                }
                {
                    subTitle5 &&
                    <><div className={'RoadmapCard__subTitle'}>
                        {subTitle5}
                    </div>
                    <div className={'RoadmapCard__content'}>
                        {t(content5)}
                    </div></>
                }
            </div>
        </div>
    )
}

const RoadmapPage = () => {
    const {t} = useTranslation();

    return (
        <div className={'Page RoadmapPage'}>
            <Section className={'RoadmapSection'}>
                <div className={'SectionContainer'}>
                    <h1>
                        {t('roadmap.title')}
                    </h1>

                    <div className={'RoadmapCards'}>
                        <RoadmapCard title={t('roadmap.2025-1.H1-1.title')}
                                     subTitle1={t('roadmap.2025-1.H1-1.subTitle1')}
                                     subTitle2={t('roadmap.2025-1.H1-1.subTitle2')}
                                     subTitle3={t('roadmap.2025-1.H1-1.subTitle3')}
                                     subTitle4={t('roadmap.2025-1.H1-1.subTitle4')}
                                     content={t('roadmap.2025-1.H1-1.content')}
                                     content2={t('roadmap.2025-1.H1-1.content2')}
                                     content3={t('roadmap.2025-1.H1-1.content3')}
                                     content4={t('roadmap.2025-1.H1-1.content4')}
                                     year={'2025'}
                                     period={'H1'}
                                     color={'blue'}
                        />
                        <RoadmapCard title={t('roadmap.2025-1.H1-2.title')}
                                     subTitle1={t('roadmap.2025-1.H1-2.subTitle1')}
                                     subTitle2={t('roadmap.2025-1.H1-2.subTitle2')}
                                     subTitle3={t('roadmap.2025-1.H1-2.subTitle3')}
                                     subTitle4={t('roadmap.2025-1.H1-2.subTitle4')}
                                     content={t('roadmap.2025-1.H1-2.content')}
                                     content2={t('roadmap.2025-1.H1-2.content2')}
                                     content3={t('roadmap.2025-1.H1-2.content3')}
                                     content4={t('roadmap.2025-1.H1-2.content4')}
                                     year={'2025'}
                                     period={'H1'}
                                     color={'blue'}
                        />
                        <RoadmapCard title={t('roadmap.2025-2.H1-1.title')}
                                     subTitle1={t('roadmap.2025-2.H1-1.subTitle1')}
                                     subTitle2={t('roadmap.2025-2.H1-1.subTitle2')}
                                     subTitle3={t('roadmap.2025-2.H1-1.subTitle3')}
                                     content={t('roadmap.2025-2.H1-1.content')}
                                     content2={t('roadmap.2025-2.H1-1.content2')}
                                     content3={t('roadmap.2025-2.H1-1.content3')}
                                     year={'2025'}
                                     period={'H2'}
                                     color={'green'}
                        />
                        <RoadmapCard title={t('roadmap.2025-2.H1-2.title')}
                                     subTitle1={t('roadmap.2025-2.H1-2.subTitle1')}
                                     subTitle2={t('roadmap.2025-2.H1-2.subTitle2')}
                                     subTitle3={t('roadmap.2025-2.H1-2.subTitle3')}
                                     subTitle4={t('roadmap.2025-2.H1-2.subTitle4')}
                                     content={t('roadmap.2025-2.H1-2.content')}
                                     content2={t('roadmap.2025-2.H1-2.content2')}
                                     content3={t('roadmap.2025-2.H1-2.content3')}
                                     content4={t('roadmap.2025-2.H1-2.content4')}
                                     year={'2025'}
                                     period={'H2'}
                                     color={'yellow'}
                        />

                    </div>
                </div>
            </Section>

            <BottomNavigator
                prev={{
                    to: '/apps',
                    title: t('navbar.apps')
                }}
                next={{
                    to: '/docs',
                    title: t('navbar.docs')
                }}/>
        </div>
    )
};

export default RoadmapPage;